import React from "react"
import { Link } from "gatsby"
import Header from "../components/Header"
import Footer from "../components/Footer"
import SEO from "../components/seo"
import learning2 from "../images/learning 2.webp"
import learning2Low from "../images/learning 2 (1).webp"
import learing3 from "../images/learning 3.webp"
import learing3Low from "../images/learning 3 (1).webp"
import learing4 from "../images/learning 4 (1).webp"
import learing4Low from "../images/learning 4.webp"
import donate from "../images/Group 12.svg"
import volunteer from "../images/Group 13.svg"
import partner from "../images/Group 14.svg"
import LazyImage from "../components/LazyImage"
import Slide2Low from "../images/slide2_low.webp"
import Slide2 from "../images/slide2.webp"

export default function learningProcess() {
  return (
    <div>
      <SEO title="Learning Process" />
      <Header />
      <br />
      <br />
      <br />
      <br />
      <div className="container d-flex justify-content-center">
        <div className="row">
          <div className="col">
            <br />
            <h3>Explore the learning process of </h3>
            <h1 className="text-center">
              <b>Swapnanagar Kids</b>
            </h1>
          </div>
        </div>
      </div>
      <div className="container-fluid m-0 p-0 overflow-hidden position-relative">
        <div className="row">
          <div className="col-lg-12">
            <LazyImage
              lowRes={Slide2Low}
              highRes={Slide2}
              width="100%"
              alt="learing1"
            ></LazyImage>
          </div>
        </div>
      </div>
      <div className="position-absolute learning-2">
        <LazyImage
          lowRes={learning2Low}
          highRes={learning2}
          width="100%"
          alt="learing2"
        ></LazyImage>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <h1>Fun & fear-free learning</h1>
            <p>
              Unfortunately, traditional primary schools in Bangladesh tend to
              be treated as high-stakes education where students feel threatened
              by the system and the school environment. At Swapnanagar we
              believe that positive experiences are a far superior motivation
              for students to learn what they need to succeed in a fast-changing
              world. Our school is a safe space where students are accepted for
              who they are as children and always treated with the respect and
              understanding that they deserve.
            </p>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="position-absolute learning-3">
        <LazyImage
          lowRes={learing3Low}
          highRes={learing3}
          width="100%"
          alt="learing3"
        ></LazyImage>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-5"></div>
          <div className="col-lg-7">
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <h1>
              Adopting <b>Active Learning</b>
            </h1>
            <p>
              At Swapnanagar students are encouraged to think through and make
              and use associations to solve problems rather than relying on
              memorization and repetition. Through an active learning process,
              students gain the skills to ask the right questions, think for
              themselves, and gain the knowledge they need in life - where
              traditional schooling relies on memorization and repetition. We
              believe this gives our students a leg up in the world they live
              in.
            </p>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div className=" position-absolute learning-2">
        <LazyImage
          lowRes={learing4Low}
          highRes={learing4}
          width="100%"
          alt="learing4"
        ></LazyImage>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <h1>No fixed classroom</h1>
            <p>
              Open classrooms promote student engagement and increased lesson
              retention by allowing students to have a say in which class to
              attend and in the nature of their classroom environment. When it’s
              sunny out, the students can vote to go outside for class. And if a
              student finds a certain lesson less interesting, they can choose
              to attend other classes that suit their interests more. Giving
              children this freedom gives students a sense of control over their
              own education and promotes independent learning and engagement
              with their education.
            </p>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="container justify-content-center">
        <div className="row justify-content-center text-center">
          <Link
            to="/donate"
            className="col-lg-3 col-sm-6 no-style-btn text-dark"
          >
            <img src={donate} alt="shoolarship" />
            <div className="p-4 text-center">
              <p>Donate</p>
            </div>
          </Link>
          <Link
            to="/volunteer"
            className="col-lg-3 col-sm-6 no-style-btn text-dark"
          >
            <img src={volunteer} alt="childLabour" />
            <div className="p-4 text-center">
              <p>Volunteer</p>
            </div>
          </Link>
          <Link
            to="/partner"
            className="col-lg-3 col-sm-6 no-style-btn text-dark"
          >
            <img src={partner} alt="childMarraige" />
            <div className="p-4 text-center">
              <p>Be a partner</p>
            </div>
          </Link>
        </div>
      </div>
      <br />
      <Footer />
    </div>
  )
}
